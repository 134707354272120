import React, { Component } from 'react';
import '../App.css'; // 导入自定义CSS样式

class RentIndexPart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isWindowOpen: props.viewedPolygon && Object.keys(props.viewedPolygon).length > 0,
    };
  }

  closeWindow = () => {
    this.setState({ isWindowOpen: false });
  };

  componentDidUpdate(prevProps) {
    if (this.props.viewedPolygon !== prevProps.viewedPolygon) {
      const isOpen = this.props.viewedPolygon && Object.keys(this.props.viewedPolygon).length > 0;
      this.setState({ isWindowOpen: isOpen });
    }
  }

  render() {
    const { isWindowOpen } = this.state;
    const viewedPolygon = this.props.viewedPolygon;

    return (
      <div>
        {/* 飘窗内容 */}
        {isWindowOpen && viewedPolygon && typeof viewedPolygon === 'object' && Object.keys(viewedPolygon).length > 0 && (
          <div className="floating-window">
            <div>
              <h4>駅名: {viewedPolygon.stationName}</h4>
              <h4>アクセス時間: {Math.floor(viewedPolygon.accessTimeApi)} 分</h4>
              <div>
                <table>
                  <thead>
                    <tr>
                      <th>間取り</th>
                      <th>賃料相場</th>
                    </tr>
                  </thead>
                  <tbody>
                    {['1K', '1DK', '1LDK', '2DK', '2LDK', '3LDK'].map(layout => {
                      const layoutItem = viewedPolygon.layouts.find(item => item.layout === layout);
                      if (layoutItem) {
                        const rentMeanFormatted = Math.floor(layoutItem.rent_mean / 1000)/10;
                        return (
                          <tr key={layout}>
                            <td>{layout}</td>
                            <td>{rentMeanFormatted}万円</td>
                          </tr>
                        );
                      }
                      return null;
                    })}
                  </tbody>
                </table>
              </div>
              <button className='close_button' onClick={this.closeWindow}>X</button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default RentIndexPart;
