import React, { Component} from 'react';
import { GoogleMap, PolygonF, MarkerF } from "@react-google-maps/api";
import * as d3 from 'd3'; // Import the d3 library
import stations from "../data/stations.json"
import BestBashoApiPart from './BestBashoApiPart';
import RentIndexPart from './RentIndexPart'
import { Amplify, Auth } from "aws-amplify";
import awsconfig from "../aws-exports";

// const bestBashoApiKey = process.env.REACT_APP_PUBLIC_BESTBASHO_API_KEY;

const redirectUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
Amplify.configure({
  ...awsconfig,
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    region: "ap-northeast-1",
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
    cookieStorage: {
      // 環境変数の名前は適宜変更する
      domain: `.${process.env.REACT_APP_CUSTOM_DOMAIN}`,
    },
    oauth: {
      domain: process.env.REACT_APP_OAUTH_DOMAIN,
      scope: [
        "phone",
        "email",
        "openid",
        "profile",
        "aws.cognito.signin.user.admin",
      ],
      redirectSignIn: redirectUrl,
      redirectSignOut: redirectUrl,
      responseType: "code",
    },
  },

});

// 定义常用选项
const polygonOptions = {
  fillOpacity: 0.7,
  strokeWeight: 0.8,
  strokeColor: '#000000',
  strokeOpacity: 0.2,
  bestBashoCost: -1,
  zIndex: 2,
};

class GoogleMapsPart extends Component {
  constructor(props) {
    super(props);
    this.rentIndexPartRef = React.createRef(); // Create a reference for RentIndexPart
    this.state = {
      maxPolygonInfos: 5, // Maximum number of saved items
    };
  }
  
  componentDidMount() {
    // Initialize defaultMap with data from stations.json
    const defaultMap = stations.map(feature => {
      const id = feature.properties.id;
      const accessTime = 50;
      const accessTimeApi = null;
      const geometry = feature.geometry;
      const stationName = feature.properties.stationName.replace(/\//g, ''); // Remove slashes
      const layouts = feature.properties.layouts;
      const footprints = feature.properties.footprints;
      const rppa_info = feature.properties.rppa_info;
      const polygonCenter = this.calculatePolygonCenter(geometry.coordinates[0]);
      const filterResult = null;
      const accessTimeFilterResult = null;

      return { id, accessTime,accessTimeApi ,geometry, stationName,layouts,footprints,rppa_info, polygonCenter,filterResult,accessTimeFilterResult};
    });
    this.props.setDefaultMap(defaultMap);
  };

  calculatePolygonCenter(coordinates) {
    if (coordinates.length > 1) {
      const numPoints = coordinates.length;
      let xSum = 0;
      let ySum = 0;
      coordinates.forEach(point => {
        xSum += point[0];
        ySum += point[1];
      });
      const centerX = xSum / numPoints;
      const centerY = ySum / numPoints;
      return { lat: centerY, lng: centerX };
    } else {
      const numPoints = coordinates[0].length;
      let xSum = 0;
      let ySum = 0;
      coordinates[0].forEach(point => {
        xSum += point[0];
        ySum += point[1];
      });
      const centerX = xSum / numPoints;
      const centerY = ySum / numPoints;
      return { lat: centerY, lng: centerX };
    }
  };
  
  handleSearchModeClick = (polygonData) => {
    const existingIndex = this.props.PolygonInfo.findIndex(info => info.id === polygonData.id);
    const tempCPI = [...this.props.PolygonInfo];
  
    if (existingIndex !== -1) {
      // If it already exists, remove it from the array
      tempCPI.splice(existingIndex, 1,'');
    } else {
      const emptyIndex = tempCPI.findIndex(info => info === '');
      if (emptyIndex !== -1) {
        // Replace the first empty string with polygonData
        tempCPI[emptyIndex] = polygonData;
      } else if (tempCPI.length < this.state.maxPolygonInfos) {
        // If there's no empty slot and the number is not at the limit, append it to the end of the array
        tempCPI.push(polygonData);
      } else {
        alert("最大5個の駅まで");
        return;
      }
    }
  
    this.props.setPolygonInfo(tempCPI);
  };
  
  handleViewModeClick = (data) => {
    const vieweddata = data;
    this.props.setViewedPolygon(vieweddata);
  }

  // Add handleMarkerClick method in GoogleMapsPart component
  handleMarkerClick = (clickedPolygon) => {
    const { PolygonInfo, setPolygonInfo } = this.props;

      // Execute the following only when IsViewMode is false
      // Find the index of the PolygonInfo to delete
      const indexToDelete = PolygonInfo.findIndex(info => info.id === clickedPolygon.id);

    if (indexToDelete !== -1) {
        // If the item to be deleted is found, remove it from the array
        const updatedPolygonInfo = [...PolygonInfo];
      updatedPolygonInfo.splice(indexToDelete, 1);
      setPolygonInfo(updatedPolygonInfo); // // Update the PolygonInfo state
    }
  };

  render() {
    // Get apiResponse from props
    let windowCenter = {
      lat: 35.69575,
      lng: 139.77521,
    };
    //把窗口中心换成最新的点击点

    // const lastNonEmptyPolygonInfo = this.props.PolygonInfo
    //   .filter(PolygonInfo => PolygonInfo !== '') // Filter out non-empty PolygonInfo
    //   .slice(-1)[0]; // Get the last non-empty PolygonInfo

    // if (lastNonEmptyPolygonInfo) {
    //   windowCenter = lastNonEmptyPolygonInfo.polygonCenter; // Use the center point of the last non-empty PolygonInfo
    // }
    
    // 把窗口中心换成点击polygon的中心
    let validPolygonCount = 0; // 用于跟踪有效的 PolygonInfo 数量
    let centerSum = { lat: 0, lng: 0 };

    this.props.PolygonInfo.forEach(PolygonInfo => {
      if (PolygonInfo !== '') {
        centerSum.lat += PolygonInfo.polygonCenter.lat;
        centerSum.lng += PolygonInfo.polygonCenter.lng;
        validPolygonCount++;
      }
    });

    if (validPolygonCount > 0) {
      windowCenter = {
        lat: centerSum.lat / validPolygonCount,
        lng: centerSum.lng / validPolygonCount,
      };
    }
    
    const containerStyle = {
      width: "100%",
      height: "100vh",
      display: "flex",
    };
    // Create color scale and custom interpolation function
    const colorScale = d3.scaleSequential(d3.interpolateSpectral)
      .domain([120, 0]); // Reverse color mapping
    const legendColorStops = Array.from({ length: 121 }, (_, i) => {
      const color = colorScale(i);
      return { offset: i, color };
    });
    const handleSignOut = async () => {
      try {
        await Auth.signOut();
        console.log('User signed out');
        // 添加其他注销后的逻辑，例如重定向到登录页等
      } catch (error) {
        console.error('Error signing out', error);
      }
    };
  

    return (
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={windowCenter}
        zoom={12}
        options={{
          disableDefaultUI: true,
          styles: [
            {
              featureType: "poi",
              elementType: "labels",
              stylers: [
                { visibility: "off" } // 关闭兴趣点标签
              ]
            },
            {
              featureType: "transit.station",
              elementType: "labels",
              stylers: [
                { visibility: "on" } // 显示车站标签
              ]
            },
            {
              featureType: "road",
              elementType: "labels",
              stylers: [
                { visibility: "off" } // 隐藏道路标签
              ]
            },
            {
              featureType: "transit.line",
              elementType: "geometry",
              stylers: [
                { color: "#FFC0CB" } // 设置道路的颜色为？？色
              ]
            },
            {
              featureType: "landscape.natural",
              elementType: "geometry.fill",
              stylers: [
                { color: "#dcdcdc" } // 设置自然景观的颜色为浅灰色
              ]
            },   
            {
              featureType: "water",
              elementType: "geometry.fill",
              stylers: [
                { color: "#87CEEB" } // 设置海洋和河流的颜色为深灰色
              ]
            },     
            {
              featureType: "poi.park",
              elementType: "geometry.fill",
              stylers: [
                { color: "#dcdcdc" } // 
              ]
            },   
            {
              featureType: "road.highway",
              elementType: "geometry.stroke",
              stylers: [
                { visibility: "off" } // 设置道路边界线的颜色为黄色
              ]
            }
          ]
        }}
      >
          <button onClick={handleSignOut} className="button"　 style={{
            position: 'absolute',
            top: '0px',
            left: '10px',}}>Sign Out</button>

        {/* input box */}
        <div
          style={{
            position: 'absolute',
            top: '50px',
            left: '10px',
            height: '75%', // 90% as height
            backgroundColor: 'white',
            padding: '10px',
            borderRadius: '8px',
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
            zIndex: 1000, // Make the input box visible on the map
            opacity: 0.7, // Set the opacity effect, value between 0 and 1
            overflow: 'auto', // or 'scroll'
          }}
        >
          {/* <button onClick={handleSignOut} className="button"　 style={{
            position: 'absolute',
            top: '25px',
            left: '230px',}}>Sign Out</button> */}

          <BestBashoApiPart
            // 传递 BestBashoApiPart 所需的属性
            setDefaultMap={this.props.setDefaultMap} 
            defaultMap={this.props.defaultMap}
            setPolygonInfo={this.props.setPolygonInfo}
            PolygonInfo={this.props.PolygonInfo}
            setIsViewMode={this.props.setIsViewMode}
            setViewedPolygon={this.props.setViewedPolygon}
          />
        </div>

        {/* 放置polygon */}
        {this.props.defaultMap.map(data => {
          // const rppaColorScale = d3.scaleLinear()
          // .domain([0, 10]) // 假设 rppa 的范围是 0 到 10
          // .range(["green", "red"]); // 对应颜色范围
          
          const coordinates = data.geometry.coordinates[0];
          const fillColor = data.accessTimeFilterResult !==0 && data.filterResult !==0 && data.accessTimeApi !== null
          ? colorScale(data.accessTimeApi) // 使用 accessTimeApi 的值来计算 fillColor
          : "#CCCCCC"; // 设置为浅灰色

          const handleClick = () => {
            if (this.props.IsViewMode) {
              this.handleViewModeClick(data);
            } else {
              this.handleSearchModeClick(data);
            }
          };

          let polygons = null;
          if (coordinates.length > 1) {
            // Single polygon
            const processedCoordinates = coordinates.map(coord => {
              const lat = parseFloat(coord[1].toFixed(7));
              const lng = parseFloat(coord[0].toFixed(7));
              return {
                lat: lat,
                lng: lng
              };
            });
            polygons = (
              <PolygonF
                key={data.id}
                paths={processedCoordinates}
                options={{ ...polygonOptions, fillColor,       
                  strokeOpacity: 0.3, // 透明度
                  strokeWeight: 0.3,}} // 线宽
                onClick={handleClick}                  
              />
            );
          } else {
            // Multi-polygon
            polygons = coordinates.map(polygon => {
              const processedCoordinates = polygon.map(coord => {
                const lat = parseFloat(coord[1].toFixed(7));
                const lng = parseFloat(coord[0].toFixed(7));
                return {
                  lat: lat,
                  lng: lng
                };
              });
              return (
                <PolygonF
                  key={data.id}
                  paths={processedCoordinates}
                  options={{ ...polygonOptions, fillColor,       
                    strokeOpacity: 0.2, // 透明度
                    strokeWeight: 0.5,}} // 线宽
                  onClick={handleClick}                  
                />
              );
            });
          }
          return polygons;
        })};

        {this.props.PolygonInfo.map((PolygonInfo, index) => (
          
          PolygonInfo !== '' && (
            <MarkerF
              key={PolygonInfo.id}
              position={PolygonInfo.polygonCenter}
              icon={{
                url: process.env.PUBLIC_URL + `/icons8-marker-50.png`,
                scaledSize: new window.google.maps.Size(32, 32),
              }}
              onClick={() => this.handleMarkerClick(PolygonInfo)} // 在点击时调用 handleMarkerClick
              />
          )
        ))}
        {this.props.viewedPolygon && (
          <MarkerF
            key={this.props.viewedPolygon.id}
            position={this.props.viewedPolygon.polygonCenter}
            icon={{
              url: process.env.PUBLIC_URL + '/icons8-visit-50.png',
              scaledSize: new window.google.maps.Size(32, 32), // 设置所需的宽度和高度
            }}
          />
        )}
          {/* Legend */}
          <div
            style={{
              position: 'absolute',
              bottom: '25px',
              left: '10px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
          <div
            style={{
              width: '400px',
              height: '15px',
              background: `linear-gradient(to left, ${legendColorStops
                .map(stop => stop.color)
                .join(',')})`,
              opacity: 0.7,
              marginRight: '8px',
            }}
          ></div>
            {/* Axis Labels */}
          <div style={{ display: 'flex', flexDirection: 'row' }}>
              <span
                style={{
                  fontSize: '15px',
                }}
              >BEST
              </span>
          </div>

          <RentIndexPart 
          ref={this.rentIndexPartRef}
          viewedPolygon={this.props.viewedPolygon}
          />
        </div>
      </GoogleMap>
    ); 
  }
}

export default GoogleMapsPart;
