import React, { Component } from 'react';
import '../App.css'; // 导入自定义CSS样式
import Select from 'react-select';
import { API } from "aws-amplify";

// import {Stack, Button } from 'react-bootstrap';

// const bestBashoApiKey = process.env.REACT_APP_PUBLIC_BESTBASHO_API_KEY;



class BestBashoApiPart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputStations: ['', '', '', '', ''],
      layout:['1K', '1LDK', '2LDK', '3LDK'],
      selectedLayout: '', // 跟踪被选择的按钮
      rent:[25000,50000,75000,100000,125000,150000,175000,200000],
      // selectedRentMin:'',
      selectedRentMax:'',
      preparedAccessTime:[10,20,30,40,50,60,90,120],
      selectedAccessTime:'',
      selectedValue: '', // 新增 selectedValue 状态
      isSearching: false, // 添加 isSearching 状态
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.PolygonInfo !== prevProps.PolygonInfo) {
      const PolygonInfoIds = this.props.PolygonInfo.map(item => item.id);

      const newInputStations = Array(5).fill('');

      for (let i = 0; i < Math.min(PolygonInfoIds.length, newInputStations.length); i++) {
        if (PolygonInfoIds[i]) {
          newInputStations[i] = PolygonInfoIds[i];
        }
      }
      this.setState({
        inputStations: newInputStations,
        selectedValue: newInputStations 
      });

    };
    if (
      prevState.selectedLayout !== this.state.selectedLayout ||
      // prevState.selectedRentMin !== this.state.selectedRentMin ||
      prevState.selectedRentMax !== this.state.selectedRentMax
    ) {
      // 调用新的函数检查并执行筛选操作
      this.checkAndPerformProcessing();
    };
    // 判断 this.props.defaultMap 的 accessTimeApi 是否发生了变化
    const accessTimeApiChanged =
      prevProps.defaultMap.some((prevItem, index) => prevItem.accessTimeApi !== this.props.defaultMap[index].accessTimeApi);

    if (
      prevState.selectedAccessTime !== this.state.selectedAccessTime ||
      accessTimeApiChanged
    ) {
      // 调用新的函数检查并执行筛选操作
      this.checkAccessTime();
    }
  }

  handleInputStationChange = (selectedOption, index) => {
    const tempIPI = [...this.props.PolygonInfo];
    console.log(selectedOption)
    if (selectedOption) {
      const { value } = selectedOption;
      const matchingStation = this.props.defaultMap.find(station => station.id === value);

      if (matchingStation) {
        tempIPI.splice(index, 1, matchingStation); // 替换掉对应的元素
      }
    } else {
      tempIPI.splice(index, 1, ''); // 替换掉对应的元素
    }
  
    this.props.setPolygonInfo(tempIPI);
  };

  resetSearch = () => {
    // 执行重置搜索的操作
    // 设置为搜索模式
    this.props.setIsViewMode(false);
    this.props.setPolygonInfo([]);
    this.props.setViewedPolygon([]);
    const updatedDefaultMap = this.props.defaultMap.map(data => {
      return { ...data, accessTimeApi: null,accessTimeFilterResult: null, filterResult:null };
    });
    // Call setDefaultMap with the updated array
    this.props.setDefaultMap(updatedDefaultMap);
    this.setState({
      selectedLayout: '',
      // selectedRentMin: '',
      selectedRentMax: '',
      selectedAccessTime: '',
    });
  };
  fetchData = async () => {
    try {
      this.setState({ isSearching: true }); // 设置为搜索中
      const nonEmptyStations = this.state.inputStations
        .filter(station => station.trim() !== '')
        .map(station => 'vor_' + station);

      // const request = {
      //   method: 'POST',
      //   body: JSON.stringify({ stations: nonEmptyStations }),
      //   mode: 'cors',
      //   headers: {
      //     'content-type': 'application/json',
      //     'x-api-key': bestBashoApiKey,
      //   },
      // };

      const apiName = 'bestbasho_amplify'; // 替换成你的 API 名称
      const path = '/'; // 替换成你的 API 路径
      const myInit = {
        body: { stations: nonEmptyStations },
      };
  
      const response = await API.post(apiName, path, myInit);    
      // const response = await fetch('https://nys1632t61.execute-api.ap-northeast-1.amazonaws.com/dev/', request);
      const accessTimeObject = JSON.parse(response);
      const apiResponse = accessTimeObject.features.map(feature => {
        const id = feature.properties.id.replace('vor_', '');
        const accessTime = feature.properties.results1;
        const geometry = feature.geometry;
        const stationName = feature.properties.stationName.replace(/\//g, '');
        return { id, accessTime, geometry, stationName };
      });
      // 更新 this.props.defaultMap 中的相应项的 accessTimeApi
      const updatedDefaultMap = this.props.defaultMap.map(item => {
        const matchingApiResponse = apiResponse.find(apiItem => apiItem.id === item.id);
        if (matchingApiResponse) {
          return { ...item, accessTimeApi: matchingApiResponse.accessTime };
        }
        return item;
      });
      this.props.setDefaultMap(updatedDefaultMap); // 更新默认地图数据
      this.props.setIsViewMode(true);
      this.setState({ isSearching: false }); // 设置为搜索完成

    } catch (error) {
      console.error('Error fetching data:', error);
      this.setState({ isSearching: false }); // 设置为搜索完成
    }
  };

  selectedLayout = filterValue => {
    this.setState({
      selectedLayout: filterValue, // 更新被选择的按钮
    });
    
  };
  // selectedRentMin = filterValue => {
  //   this.setState({
  //     selectedRentMin: parseInt(filterValue), // 更新被选择的按钮
  //   });
  // };
  selectedRentMax = filterValue => {
    this.setState({
      selectedRentMax: parseInt(filterValue), // 更新被选择的按钮
    });
  };
  selectedAccessTime = filterValue => {
    this.setState({
      selectedAccessTime: parseInt(filterValue), // 更新被选择的按钮
    });
  };

  checkAndPerformProcessing = () => {
    const { selectedLayout, selectedRentMax } = this.state;
  
    if (selectedLayout !== ''  && selectedRentMax !== '') {
      // 执行处理操作...
        const filteredMap = this.props.defaultMap.map(item => {
          const isAnyLayout = item.layouts.some(layout => {
            const isLayout = layout.layout === selectedLayout;
            const isRentInRange = layout.rent_mean <= selectedRentMax;
            return isLayout && isRentInRange;
          });
          return {
            ...item,
            filterResult: isAnyLayout ? 1 : 0
          };
        });
        this.props.setDefaultMap(filteredMap);
      };
  };
  checkAccessTime = () => {
    const { selectedAccessTime } = this.state;
    if (selectedAccessTime !== '') {
      const filterAccessTime = this.props.defaultMap.map(item => {
        if (item.accessTimeApi !== null) { // Check if accessTimeApi is not null
          const isAccessTimeInRange = item.accessTimeApi <= selectedAccessTime;
          return {
            ...item,
            accessTimeFilterResult: isAccessTimeInRange ? 1 : 0
          };
        }
        return item; // No change if accessTimeApi is null
      });
      this.props.setDefaultMap(filterAccessTime);
    };
  }

  render() {
    const { inputStations,isSearching } = this.state;
    const buttonText = isSearching ? '検索中...' : '検索';
    const spinnerClass = isSearching ? 'spinner' : '';
    const { defaultMap } = this.props;
    const nullOption = {
      value : '駅を選択する',
      label : ''
    }
    return (
      <div className="input-section">
        <div className="logo-container">
          <img
            src="../BestBasho_Logo_v2b.png"
            alt="Logo1"
            width={200}
            height={45}
          />
        </div>
        {inputStations.map((inputStation, index) => {
          const stationOptions = defaultMap.map(item => ({
            value: item.id,
            label: item.id
          }));
          const selectedOption = stationOptions.find(option => option.value === inputStation) || nullOption;
          const showClearButton = selectedOption !== nullOption;

          return (
            <Select
              key={index}
              options={stationOptions}
              value={selectedOption}
              onChange={selectedOption => {
                // 在这里实时监测 selectedOption 的值
                this.handleInputStationChange(selectedOption, index);
              }}
              onInputChange={(inputValue, { action }) => {
                if (action === 'input-change') {
                  console.log(inputValue);
                }}}
              isClearable={showClearButton} // 根据条件设置是否显示清除按钮
              styles={{ control: provided => ({ ...provided, width: '200px' }) }}
              placeholder={selectedOption.label}
            />
          );
        })}<br />
        <div >
          <button onClick={this.fetchData} className="button">
            {buttonText}
            {isSearching && <span className={`spinner ${spinnerClass}`}>&#x21bb;</span>}
          </button>
          <span style={{ margin: '0 17px' }}></span> {/* 插入空白 */}
          <button onClick={this.resetSearch} className="button">条件クリア</button>
        </div>
        
        <div className="filter-dropdown">
          <h3>----条件でフィルタリング----</h3>
          {/* <h4>時間範囲：</h4>
          <select
            value={this.state.selectedAccessTime}
            onChange={event => this.selectedAccessTime(event.target.value)}
          >
            {this.state.preparedAccessTime.map(mintime => (
              <option key={mintime} value={mintime}>
                {mintime}分以内
              </option>
            ))}
          </select> */}

          <h4>間取り：</h4>
          <div style={{ display: 'flex', gap: '5px' }}> {/* 使用 flex 布局，设置间距 */}
            {this.state.layout.map(layout => (
              <button
                key={layout}
                onClick={() => this.selectedLayout(layout)} // 将 layout 值传递给 selectedLayout 函数
                className={`button ${this.state.selectedLayout === layout ? 'active-layout' : ''}`}
              >
                {layout}
              </button>
            ))}
          </div>

          <h4>最大賃料：</h4>
          <Select
            value={{ value: this.state.selectedRentMax, label: this.state.selectedRentMax.toString() }}
            onChange={selectedOption => this.selectedRentMax(selectedOption.value)}
            options={this.state.rent.map(Maxrent => ({
              value: Maxrent,
              label: Maxrent.toString()
            }))}
            styles={{ control: provided => ({ ...provided, width: '200px' }) }} // 设置宽度为100px
            className="filter-input"
          />
        </div>
      </div>
    );
    
  }
}

export default BestBashoApiPart;
