import './App.css';
import GoogleMapsPart from './components/GoogleMap'
import { useState, useEffect } from 'react';
import { LoadScript } from '@react-google-maps/api';

import { Authenticator, Text, View, useTheme } from '@aws-amplify/ui-react'
import { Amplify, Auth, API } from "aws-amplify";
import '@aws-amplify/ui-react/styles.css'
// import awsExports from "./aws-exports"
import awsconfig from "./aws-exports";

const mapsApiKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

// const bestBashoApiKey = process.env.REACT_APP_PUBLIC_BESTBASHO_API_KEY;

const redirectUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
Amplify.configure({
  ...awsconfig,
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    region: "ap-northeast-1",
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
    cookieStorage: {
      // 環境変数の名前は適宜変更する
      domain: `.${process.env.REACT_APP_CUSTOM_DOMAIN}`,
    },
    oauth: {
      domain: process.env.REACT_APP_OAUTH_DOMAIN,
      scope: [
        "phone",
        "email",
        "openid",
        "profile",
        "aws.cognito.signin.user.admin",
      ],
      redirectSignIn: redirectUrl,
      redirectSignOut: redirectUrl,
      responseType: "code",
    },
  },
  API: {
    endpoints: [
      {
        name: process.env.REACT_APP_API_NAME,
        endpoint: process.env.REACT_APP_API_ENDPOINT,
        custom_header: async () => {
          return {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getAccessToken()
              .getJwtToken()}`,
          };
        },
      },
    ],
  },
});

function App() {
 
  const startLambda = async () => {
    try {
      const apiName = process.env.REACT_APP_API_NAME; // 替换成你的 API 名称
      const path = '/'; // 替换成你的 API 路径
      const myInit = {
        body: 'lambda',
      };
      const response = await API.post(apiName, path, myInit);
      console.log(response);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    console.log('useEffect executed');
    // 这里写入你想要在页面加载时触发的代码
    startLambda();
  }, []); // 注意传递一个空数组作为第二个参数，以确保只在挂载时触发

  const handleSignOut = async () => {
    try {
      await Auth.signOut();
      console.log('User signed out');
      // 添加其他注销后的逻辑，例如重定向到登录页等
    } catch (error) {
      console.error('Error signing out', error);
    }
  };

  const [defaultMap, setDefaultMap] = useState([]);
  const [viewedPolygon, setViewedPolygon] = useState([]); // 初始化 apiResponse 状态
  const [PolygonInfo,setPolygonInfo] = useState(['','','','','']); // 初始化 defaultMap 状态
  const [IsViewMode,setIsViewMode] = useState(false);
  return (
    <><Authenticator hideSignUp={true} socialProviders={['google']} components={{
      Footer() {
        const { tokens } = useTheme();

        return (
          <View textAlign="center" padding={tokens.space.large}>
            <Text color={tokens.colors.neutral[80]}>
              ログインすると、
              <a
                href={`https://${
                  process.env.REACT_APP_CUSTOM_DOMAIN
                }/term_of_service.pdf`}
                target="_blank"
                rel="noreferrer"
              >
                利用規約
              </a>
              に同意したものとします
            </Text>
            <Text color={tokens.colors.neutral[80]}>
              &copy; GA technologies AI Strategy Center. All Rights Reserved
            </Text>
          </View>
        );
      },
      SignIn: {},
    }}>
      <div>
      <div className="app-container">
        <LoadScript googleMapsApiKey={mapsApiKey}>
          {/* <button onClick={handleSignOut} className="button">Sign Out</button> */}
          <GoogleMapsPart setDefaultMap={setDefaultMap} defaultMap={defaultMap} setPolygonInfo={setPolygonInfo} PolygonInfo={PolygonInfo} setViewedPolygon={setViewedPolygon} viewedPolygon={viewedPolygon} setIsViewMode={setIsViewMode} IsViewMode={IsViewMode} ClickSignout={handleSignOut} />
        </LoadScript>
      </div>
    </div>
    </Authenticator></>
  );
}

export default App;
